.main-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 80vh;
  position: relative;
  width: 100%; 
  margin-bottom: 1em;
}

.login-container {
    width: 20em;
    margin: 0 auto;
    padding: 2em;
    border: 0.1em solid #ddd;
    border-radius: 0.4em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.login-header {
  text-align: center;
  margin-bottom: 2em;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.form-group label {
  display: block;
  margin-bottom: 0.4em;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 0.5em;
  border: 0.1em solid #ddd;
  border-radius: 0.2em;
  font-size: 1em;
}

.login-button {
  background-color: var(--cyan);
  color: var(--black);
  border: none;
  padding: 10px 0;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.3em;
  margin-top: 1em;
}

.login-button:hover {
  background-color: var(--cyanlight);
}

.forgot-password, .signup {
  border: none;
  padding: 0.4em;
  border-radius: 0.4em;
}

.forgot-password:hover, .signup:hover {
  font-weight: bold;
}
  