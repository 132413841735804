.clientdata-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 1em;
}

.data-container {
  width: 60%;
  margin: 0 auto;
  padding: 2em;
  border: 0.1em solid #ddd;
  border-radius: 0.4em;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
}

.ship-data {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.data-header {
  text-align: center;
  margin-bottom: 2em;
  width: 100%;
}

.ship-data {
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: center;
  width: 90%;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.form-group label {
  display: flex;
  width: 100%;
  gap: 1em;
  margin-bottom: 0.4em;
  font-weight: bold;
}

.street-form-group {
  margin-bottom: 3em;
}

.form-group label div {
  width: 100%;
}

.form-group input {
  width: 100%;
  padding: 0.5em;
  border: 0.1em solid #ddd;
  border-radius: 0.2em;
  font-size: 1em;
}

/* --------------------------- */

.payment-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  position: relative;
  width: 100%;
  margin-bottom: 1em;
}

.payment-header {
  text-align: center;
  margin-bottom: 2em;
}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.form-group {
  display: flex;
  align-items: center;
  gap: 1em;
}

.form-group label {
  display: flex;
  align-items: center;
  margin-bottom: 0.4em;
  font-weight: bold;
}

.form-group input[type="radio"] {
  margin-right: 0.5em;
}


@media (max-width: 1000px) {
  .data-container {
    width: 90%;
  }
  .login-container {
      margin: auto;
  }
}