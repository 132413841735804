body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --blue: #132043;
  --white: #ffffff;
  --gold: #E7B10A; 
  --red: #940017;
  --black: #000000;
  --green: #1A4D2E;
  --gray: rgb(115, 115, 115);
  --cyan: #E4D5B3;
  --cyanlight:  #e4d5b3ab;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Trade Gothic Next Light', sans-serif;
  color: var(--black);
  line-height: 1.7;
  font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5em; 
}

html, body, #root, .App {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

a {
  text-decoration: none;
}

.toastify-custom {
  background: var(--white) !important;
  color: var(--black) !important;
  font-family: Arial, sans-serif;
  font-size: 1em;
  font-weight: bold;
  border-radius: 0.4em;
  padding: 1em 2em;
  box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.1);
}