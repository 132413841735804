
.product-detail-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 2em;
    margin: 2em auto;
    max-width: 40em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
}

.product-datils-images {
    min-width: 100%;
    margin-bottom: 5em;
}

.stock-advise {
    color: red;
    font-weight: bold;
}

.no-stock {
    font-weight: bold;
}

.carousel-image {
    padding: 1em; 
    width: 100%;
    height: auto; 
    object-fit: cover;
}

.image-page {
    width: 70px; 
    height: 70px;
    object-fit: cover;
}

.custom-dots {
    display: flex !important; 
    flex-direction: row;
    gap: 3em !important;
    justify-content: center !important;
}
   
.custom-dots li button {
    padding: 0;
}

.custom-dots li button:before {
    content: '';
}

.custom-dots li.slick-active img {
    border: 2px solid #000;
}

/* ------------------------- */

.review-source {
    font-size: 0.8em;
}

.product-info {
    margin: 1em;
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.product-name-details {
    font-size: 2.5em;
    font-weight: bold;
}

.product-title {
    font-size: 1.5em;
}

.product-details-description {
    margin-bottom: 2em;
    line-height: 1.6;
}

.product-price {
    font-size: 1.5em;
    margin-bottom: 2em;
}

.add-to-cart {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
}

.add-to-cart label {
    margin-right: 1em;
}

.add-to-cart input[type="number"] {
    width: 6em;
    padding: 0.5em;
    margin-right: 1em;
    border: 0.2em solid #ccc;
    border-radius: 0.2em;
}

.add-to-cart-button {
    padding: 1em 2em;
    background-color: var(--cyan);
    font-weight: bold;
    border: none;
    border-radius: 0.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
    box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.2); 
}

.reviews-section {
    background-color: #fff;
    padding: 2em;
    margin-top: 2em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.review {
    margin-bottom: 2em;
}

.review p {
    margin: 0.5em 0;
}

.back-button {
    max-width: 3em;
    padding: 1em 2em;
    background-color: var(--cyan);
    color: #fff;
    border: none;
    border-radius: 0.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.back-button:hover { 
    box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.2); 
}