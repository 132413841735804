header {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 2em; 
    justify-content: space-between;
    align-items: center;  
    padding: 1em 1em 1.5em 1em;
    background-color: var(--white);
    z-index: 9;
    width: 100%;
    border-bottom: 0.1em solid var(--gray);
}

.menu {
    display: flex;
    font-size: 0.75em;
    justify-content: center;
}

.title {
    display: flex;
    justify-content: center;
}
.title a {
    /*cursor: default;*/
    text-decoration: none;
}
.title a h1{
    font-size: 2em;
}

.checkbtn {
    color: #fff;
    cursor: pointer;
    display: none;
}

.checkbtn span {
    font-size: 1em;
    font-weight: lighter;
}

#open-menu {
    font-size: 1.7em;
}
#close-menu {
    font-size: 1.7em;
}
#check {
    display: none;
}
.checkbtn {
    display: block;
}
.menu nav {
    z-index: 10;
}

.menu nav ul {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    font-size: 1.5em;
    position: fixed;
    width: 40%;
    height: 100%;
    background: var(--white);
    top: 0em;
    left: -100%;
    text-align: center;
    transition: all .5s;
    
}

.menu nav ul li a {
    display: block;
    font-size: 1em;
    text-decoration: none;
    color: var(--black);
    transition: opacity 300ms;
    padding: 2em;
    border-bottom: solid 0.1em var(--gray);
}

.menu nav ul li a:hover, .checkbtn:hover {
    opacity: 0.7;
}
#check:checked ~ ul{
    left:0;
}
.checked {
    overflow: hidden;
}
#close-menu {
    display: none;
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black);
    opacity: 0.5;
    display: none; 
    z-index: 5;
}

.otherButtons {
    display: flex;
    gap: 2em;
    font-size: 1.5em;
    cursor: pointer;
}

.auth i, .cart i, .otherButtons i {
    font-size: 1.4em;
}

.auth:hover {
    opacity: 0.7;
}
.cart:hover {
    opacity: 0.7;
}

@media screen and (max-width: 1000px) {
    .menu nav ul {
        width: 100%;
    }

    #open-menu span {
        display: none;
    }
}