.product-catalog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5em 5em;
    margin: 2em;
}

.news {
    font-weight: bold;
    font-size: 1.1em;
}