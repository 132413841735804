.legal-container {
    padding: 2em;
    margin: 0 auto;
    max-width: 100%;
    width: 70%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.legal-container h1 {
    margin-top: 1em;
    text-align: center;
    margin-bottom: 2em;
}

.legal-container p {
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 1.5em;
}

@media (max-width: 1000px) {
    .legal-container {
        width: 90%;
    }
}