.product-card {
  width: 30em;
  min-height: 40em;
  border-radius: 0.5em;
  overflow: hidden;
  margin: 1.6em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 100%; 
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 7em;
}

.title-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 2em;
  text-align: center;
  display: block;
  width: 60%;
  height: 100%;
  background-color: #fff; 
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -2em;
  transition: transform 0.3s ease, 
}

.title-card:hover {
  transform: translateY(-10px);
}

.product-price {
  font-size: 1em;
  margin: 0;
}

.product-name {
  font-size: 1em;
  margin: 0.5em 0 0;
}


/* old catalog
.product-card {
  background-color: var(--white);
  border-radius: 1.5em;
  box-shadow: 0 0.4em 0.8em rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 20em;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 1em;
}

.product-card:hover {
  transform: translateY(-1em);
  box-shadow: 0 0.8em 1.6em rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 100%;
  height: auto;
  display: block;
  max-width: 20em;
  height: 25em;
  border-bottom: 0.2 solid lightgray;
}

.product-name {
  font-size: 1.5em;
  margin: 2em 0 1em;
  color: #333;
}

.product-subname {
  font-size: 1.25em;
}

.product-description {
  font-size: 1em;
  margin: 0 2em 1.5em;
  color: #777;
} */

