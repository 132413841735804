.sd-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding: 1em;
    place-self: center;
    
  }
  
  .card {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0.5em;
    margin: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 1rem;
  }
  
  .details {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .name-quantity {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .price {
    margin-left: 1rem;
    font-weight: bold;
  }
  