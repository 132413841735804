.sellFrom-container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1em;
    margin: 2em;
}

.buy {
    margin: auto;
    padding: 1em 6em;
    grid-column-start: 2;
}

.buy {
    min-height: 2em;
    padding: 1em;
    cursor: pointer;
    font-size: bold;
    font-size: medium;
    grid-column-start: 2; 
    width: 30%;
    cursor: pointer;
    background: var(--cyan);
    transition: all 0.5s ease;
    font-weight: bold;
}

.buy:hover {
    transform: scale(1.05);
}

@media (max-width: 1000px) {
    .sellFrom-container{
        display: flex;
        flex-direction: column;
    }
}