.product-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .productcart-image {
    max-height: 7em;
    width: auto;
    margin-right: 1em; 
    object-fit: cover; 
    border-radius: 0.2em;
  }

  .product-details {
    flex-grow: 1;
    display: flex;
  }
  
  .product-name {
    margin: 0;
    font-size: 1.2em;
  }
  
  .product-price {
    margin: 5px 0;
    color: #555;
  }
  
  .button-group {
    display: flex;
    align-items: center;
  }
  
  .button {
    background-color: var(--cyan);
    color: var(--black);
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .button:hover {
    background-color: var(--cyanlight);
  }
  
  @media screen and (max-width: 1000px) {
    .product-details {
      flex-direction: column;
    }
}