.faq-container {
    padding: 2em;
    margin: 0 auto;
    max-width: 100%;
    min-height: 80vh;
}

.faq-container h2 {
    margin-top: 1em;
    text-align: center;
    margin-bottom: 2em;
}

.faq-section {
    display: flex;
    flex-direction: column;
    gap: 2em;
    max-width: 60%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
.faq-item {
    margin-bottom: 15px;
}

.faq-question {
    background-color: var(--cyan);
    color: black;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.faq-answer {
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 5px;
    line-height: 1.8;
}

@media (max-width: 1000px) {
    .faq-section {
        max-width: 90%;
    }
}
  