/* src/Signup.css */
.signup-container {
    width: 50%;
    margin: 0 auto;
    padding: 2em;
    border: 0.1em solid #ddd;
    border-radius: 0.4em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.signup-header {
    text-align: center;
    margin-bottom: 2em;
}

.signup-form, .confirm-signup-form {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 2em;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.form-group label {
    display: block;
    margin-bottom: 0.4em;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 0.5em;
    border: 0.1em solid #ddd;
    border-radius: 0.2em;
    font-size: 1em;
}

.signup-button, .confirm-signup-button {
    background-color: var(--cyan);
    color: var(--black);
    border: none;
    padding: 10px 0;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.3em;
    margin-top: 1em;
    padding: 0.5em 1em;
}

.signup-button:hover, .confirm-signup-button:hover {
    background-color: var(--cyanlight);
}

.error-message {
    color: red;
    font-weight: bold;
    text-align: center;
}

.resend-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
}

.resend-button {
    background-color: var(--cyan);
    color: var(--black);
    border: none;
    padding: 0.5em 1em;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.3em;
    margin-top: 0.5em;
}

.resend-button:hover {
    background-color: var(--cyanlight);
}

.legal-check {
    display: flex;
    gap: 1em;
    font-size: 1em;
    font-weight: bold;
}

@media (max-width: 1250px) {
    .signup-container {
        width: 90%;
    }
}