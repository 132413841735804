.main-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 80vh;
  position: relative;
  width: 100%; 
  margin-bottom: 1em;
}

.cart-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 80%;
    margin: 4em auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .cart-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cart-footer {
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    font-weight: bold;
    align-items: end;
  }
  
  .checkout-button {
    background-color: var(--cyan);
    color: var(--black);
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 5px;
    max-width: 60%;
  }
  
  .checkout-button:hover {
    background-color: var(--cyanlight);
  }
  
  #overlay-cart {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: block;
  }

  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid var(--cyan);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }