
.footer {
    border-top: var(--gray) 0.1em solid;
    background-color: var(--white);
    color: var(--black);
    text-align: center;
    padding: 0.5em 0;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
}

.footer-top,
.footer-bottom {
    margin: 0 0.5em;
}

.footer-top {
    text-align: left;
}

.contact-info {
    list-style: none;
    padding: 0;
}

.contact-info li {
    margin-bottom: 0.3em;
}

.contact-info a {
    color: var(--black);
    text-decoration: none;
    transition: color 0.3s;
}

.contact-info a:hover {
    color: var(--gray);
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer-bottom-left,
.footer-bottom-right {
    flex: 1;
    text-align: left;
}

.footer-bottom-center {
    flex: 1;
    text-align: center;
}

.footer-bottom-left p,
.footer-bottom-center p,
.footer-bottom-right a {
    margin: 0.1em 0;
}

.footer-bottom-right {
    text-align: right;
}

.footer-bottom-right a {
    color: var(--black);
    text-decoration: none;
    margin-left: 0.4em;
    font-size: 0.9em;
}

.footer-bottom-right a:hover {
    color: var(--gray);
}