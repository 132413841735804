
/*body {
    overflow: hidden;
}*/

main {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: url('../../../public/images/background2.jpg') no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 3em;
    justify-content: center;
    align-items: center;
    margin-bottom: 3em;
}

main div {
    background: var(--white);
    margin: 1em;
    padding: 1em;
    border-radius: 0.3em;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

main div label {
    font-weight: bold;
    font-size: 1.25em;
}

main input, .main-button {
    min-height: 2em;
    padding: 1em;
    cursor: pointer;
    font-size: bold;
    font-size: medium;
}

.main-button {
    cursor: pointer;
    transition: all 1s ease;
}

.main-button:hover {
    background: var(--cyan);
    font-weight: bold;
}

.scrollButton {
    background-color: var(--cyanlight);
    border: none;
    border-radius: 50%; 
    width: 80px; 
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }
  
  .scrollButton:hover {
    background-color: var(--cyan); 
    transform: translateY(-5px); 
  }
  
  .scrollButton:focus {
    outline: none;
  }

  .fa-arrow-down {
    font-size: 1.75em; 
    color: white; 
  }

  .catalog-shorcut {
    display: none;
    justify-content: center;
  }

  @media (max-width: 1000px) {
    .main-button {
      background: var(--cyan);
      font-weight: bold;
      border: none;
  }
  }