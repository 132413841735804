.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--white);
    background: url('../../../public/images/background2.jpg') no-repeat center center;
    background-size: cover;
  }
  
  .success-message {
    text-align: center;
    background: var(--white);
    padding: 2em;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
  }
  
  .success-message h1 {
    color: var(--black);
    font-size: 2em;
    margin-bottom: 1em;
  }
  
  .success-message p {
    font-size: 1.2em;
    margin-bottom: 2em;
  }
  
  .success-home-link {
    display: inline-block;
    padding: 0.8em 1.5em;
    background: var(--cyanlight);
    color: var(--black);
    font-weight: bold;
    text-decoration: none;
    border-radius: 4px;
    transition: background 0.3s ease;
  }
  
  .success-home-link:hover {
    background: var(--cyan);
  }
  